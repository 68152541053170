import merge from 'lodash/merge';
import type { ZodRawShape } from 'zod';
import { z } from 'zod';
import type { Debugger } from 'debug';

import type { ConfigEnv } from '@reckon-web/next-config/generated';

import { GeneratedDeploymentConfigSchema } from '../schema/GeneratedDeploymentConfigSchema';

export function createStaticNextjsConfigStore(
  schema: ZodRawShape,
  logger: Debugger
): ConfigEnv {
  let config = {} as ConfigEnv;

  if (typeof document === 'undefined') {
    return config;
  }

  const defaultConfig = window.ReckonDefaultConfig || {};
  const generatedConfig = window.ReckonGeneratedConfig || {};
  const environmentConfig = window.ReckonEnvironmentConfig || {};
  const runtimeConfig = window.ReckonRuntimeConfig || {};

  config = merge(
    {},
    defaultConfig,
    generatedConfig,
    environmentConfig,
    runtimeConfig
  );

  const combinedSchema = z
    .object(schema)
    .and(z.object(GeneratedDeploymentConfigSchema));

  try {
    const outcome = combinedSchema.safeParse(config);
    if (outcome.success) {
      return outcome.data;
    }
    logger('[ERROR] Failed to validate config', config);
    for (const error of outcome.error.issues) {
      logger('[ERROR]', error);
    }
    throw new Error('Failed to validate config');
  } catch (error) {
    logger('[ERROR] Failed to parse config', error);
    throw new Error('Failed to parse config');
  }
}

declare global {
  interface Window {
    ReckonDefaultConfig?: Record<string, any>;
    ReckonGeneratedConfig?: Record<string, any>;
    ReckonEnvironmentConfig?: Record<string, any>;
    ReckonRuntimeConfig?: Record<string, any>;
  }
}
