import React from 'react';

export function StaticNextjsConfigLoader({
  assetPrefix = '',
}: {
  assetPrefix?: string;
}) {
  return (
    <>
      <script src={`${assetPrefix}/public/config.default.js`} />
      <script src={`${assetPrefix}/public/config.generated.js`} />
      <script src={`${assetPrefix}/public/config.environment.js`} />
      <script src={`${assetPrefix}/public/config.runtime.js`} />
    </>
  );
}
